body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  -ms-user-select: none;
  user-select: none;
}

/* Custom scroll */

* {
  scrollbar-width: thin !important;
  scrollbar-color: var(--chakra-colors-primary)
    var(--chakra-colors-primaryLight) !important;
}

::-webkit-scrollbar {
  border-radius: 0.375rem;
  display: block;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.375rem;
  background-color: var(--chakra-colors-grey-400) !important;
  border-right: none;
  border-left: none;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}

.tilt {
  border-color: var(--chakra-colors-primaryLight) !important;
  text-indent: initial;
  border-spacing: 2px;
  font-variant-numeric: lining-nums tabular-nums;
  border-collapse: collapse;
  width: full;
  display: table-row;
  vertical-align: inherit;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  transition: all ease;
  border-radius: 2xl;
  border-bottom: 5px solid;
  border-top: 5px solid;
  background: linear-gradient(
    135deg,
    rgb(255, 255, 255, 80%),
    rgb(255, 255, 255, 40%)
  );
}

.chrome-picker {
  width: 100% !important;
}

.chrome-picker > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) {
  display: none !important;
}

.datepicker-wrapper {
  width: 100% !important;
  display: block !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.filter-datepicker-wrapper {
  font-size: 13px;
}

.filter-datepicker {
  cursor: pointer;
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--chakra-radii-xl);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: 0.1px solid;
  border-color: #d3d3d3 !important;
  background: inherit;
  border-width: 0.1px;
  height: 28px !important;
  font-size: 13px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}

.filter-datepicker:active,
.filter-datepicker:focus {
  z-index: 1;
  border-color: #009de2;
  box-shadow: 0 0 0 1px #009de2;
}

.month-picker-clear-button {
  right: 5px !important;
}

.month-picker-clear-button::after {
  content: "X" !important;
  background-color: var(--chakra-colors-blue-500) !important;
}

.monthpicker-form-input {
  width: 100%;
  color: var(--chakra-colors-grey-900);
  border-radius: 25px;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  background-color: #fff;
  padding: 20px 40px !important;
  border: 0.5px solid var(--chakra-colors-grey-400) !important;
}

.advanced-cropper-background-image {
  display: block;
}

.react-datepicker__calendar-icon {
  padding: 12px 0 !important;
}

.datepicker-wrapper > .react-datepicker__input-container {
  display: block;
}

.datepicker-wrapper > .react-datepicker__input-container {
  font-size: 14px;
  font-family: "Roboto", "sans-serif";
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 6rem;
  border: 1px solid;
  border-color: var(--chakra-colors-grey-400) !important;
  background-color: white;
}

.datepicker-wrapper > .react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: var(--chakra-colors-blue-300) !important;
  box-shadow: 0 0 0 1px var(--chakra-colors-blue-300) !important;
}

.datepicker-wrapper > .react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 8px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--chakra-colors-gray-300);
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--chakra-colors-gray-400);
}

.react-datepicker__navigation--next {
  border-left-color: var(--chakra-colors-gray-300);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--chakra-colors-gray-400);
}

.react-datepicker__header {
  background-color: var(--chakra-colors-gray-100);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--blue300);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--chakra-colors-gray-400);
}

.page-form-item {
  margin: 20px;
}

.page-form-item > div {
  border: none !important;
}
